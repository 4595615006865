<template>
    <div>
        <div class="mp2-bg pb-5">
            <div class="container pt-4 pb-4">
                <div class="row">
                    <div class="col-12 text-center pt-5">
                        <h2>{{ $t('message.services.title') }}</h2>
                    </div>
                </div>
            </div>
        </div>

        <div class="container pt-5 pb-5 services">
            <div class="row">
                <div class="col-12 col-md-8">
                    <div class="row">
                        <div class="col-12 pb-3">
                            <h2>{{ $t('message.services.title2') }}</h2>
                        </div>
                        <div class="col-12 pb-3">
                            <p>{{ $t('message.services.text1') }}</p>
                        </div>

                        <div class="col-12 pb-2">
                            <p><strong>{{ $t('message.services.text2') }}</strong></p>
                        </div>

                        <div class="col-12 pb-3">
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <ul class="list">
                                        <li><i class="fas fa-check pr-3"></i> {{ $t('message.services.list1') }}</li>
                                        <li><i class="fas fa-check pr-3"></i> {{ $t('message.services.list2') }}</li>
                                        <li><i class="fas fa-check pr-3"></i> {{ $t('message.services.list3') }}</li>
                                        <li><i class="fas fa-check pr-3"></i> {{ $t('message.services.list4') }}</li>
                                        <li><i class="fas fa-check pr-3"></i> {{ $t('message.services.list5') }}</li>
                                    </ul>
                                </div>
                                <div class="col-12 col-md-6">
                                    <ul class="list">
                                        <li><i class="fas fa-check pr-3"></i> {{ $t('message.services.list6') }}</li>
                                        <li><i class="fas fa-check pr-3"></i> {{ $t('message.services.list7') }}</li>
                                        <li><i class="fas fa-check pr-3"></i> {{ $t('message.services.list8') }}</li>
                                        <li><i class="fas fa-check pr-3"></i> {{ $t('message.services.list9') }}</li>
                                        <li><i class="fas fa-check pr-3"></i> {{ $t('message.services.list10') }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 pb-5">
                            <p>{{ $t('message.services.text3') }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="row">
                        <div class="col-12 pb-4">
                            <img :src="require(`@/assets/images/serviçosv2_k2go.jpg`)" class="img-fluid" alt="">
                        </div>
                        <div class="col-12 pb-4">
                            <img :src="require(`@/assets/images/img_eurolavagens-350x205.jpg`)" class="img-fluid"
                                 alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mp-dark-bg">
            <div class="container">
                <WhyZone></WhyZone>
            </div>
        </div>

        <div class="mp-yellow-bg">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="d-md-flex justify-content-md-between pb-3 pb-md-0">
                            <div class="d-md-flex justify-content-md-start content">
                                {{ $t('message.services.doubt') }}<span class="pl-2" style="color:white"> {{ $t('message.services.contactus') }}!</span>
                            </div>

                            <div class="d-md-flex justify-content-md-end content">
                                <a href="tel:+351 918 925 882"><i class="fas fa-phone mr-2"></i>+351
                                    918 925 882</a>
                                <router-link class="btn btn-primary mt-2 mt-md-0"
                                             :to="{name:'contacts', params : {lang:lang}}">
<i
                                        class="far fa-envelope"></i> {{ $t('message.services.contactus') }}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mp-location-bg pt-5 pb-5">
            <Location></Location>
        </div>
    </div>
</template>

<script>

    import json     from '../../website-data.json';
    import Location from '../components/Location';
    import WhyZone  from '../components/WhyZone';

    export default {
        name       : 'ServicesPage',
        components : {
            WhyZone,
            Location
        },
        data       : function () {
            return {
                json : json,
                lang : this.$route.params.lang
            };
        },
        methods    : {}
    };
</script>
